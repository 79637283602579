/* .global-container{
    height: 87vh!important;
} */
.global-container{
  padding-top:30px;
}
.welcome-padding{
  padding:1.5rem;
}
.css-19kzrtu {
    padding: 0 !important;
}
.styles_row-title__1YiiY {
    font-size: xxx-large !important;
}
.login-main-container{
      height: 75%
}
.connect-container{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-between;
}
.oublie{
height: 80px;
 font-size: 25px!important;
 color:  #fff!important;
 border: 1px solid #ffc451 !important;
}
.connectez-vous{
    margin-top:2rem;
    width:'110%';
    height:'30%';
}
.welcome-container {
  /*background-color: rgba(0, 0, 0, 0.3);*/
  background-color: rgb(74 75 110 / 30%);
}
/* Extra small devices (phones, 1024px and down) */
  /* @media only screen and (min-width: 360px) and (max-width: 704px){
  .login-main-container{
      height: 30%
}
.oublie{
 font-size: 18px!important;
}
.lock{
    margin-bottom:'0.5rem'!important;
}
#hero h1 {
    margin-top:90px!important;
  font-size: 30px!important;

}

} */

/* @media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }

   body {
   overflow-y: visible; 
   overflow-x: visible;
}

} */

/* @media (max-width: 768px) {
  #hero {
    height: auto;
  }
   body {
   overflow-y: visible; 
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    margin-top:3rem;
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 2rem
  }

  
  .copyright {
    font-size: 0.8rem !important;
}
} */

/* Extra small devices (phones, 1024px and down) */
  /* @media only screen and (min-width: 768px) and (max-width: 1024px){
   #hero {
    height: auto;
  }
   body {
   overflow-y: visible; 
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    margin-top:3rem;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 2rem
  }

 
 #footer .copyright{
  font-size:1.2rem !important
 }

} */

