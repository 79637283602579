.custom-container{
     margin: 2% 5%;
      padding: 3% 5%; 
}

/* device from 360px , 740px (phones 740px and down) */
  @media only screen and (min-width: 360px) and (max-width: 768px){
  .custom-container{
    margin:0 !important;
    padding:0 !important
  }
  .custom-sm{
    margin-top: 3rem;
  }
  .type-container {
    width: 100%!important;
}
  .type-label{
   font-size:1.5rem!important
  }
  .type-select{
     font-size:1.4rem!important
  }
}