.fixed-element {
    position: fixed;
    top: 0;
    margin-top:5rem;
    left: 0;
    bottom: 0;
    z-index: 1;
    overflow-y: auto;
    scrollbar-width: none !important;
}

.fixed-element::-webkit-scrollbar {
    width: 0 !important;
    background-color: transparent !important;
}

.scrollable-element {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
}

:root {
    --body-bg: #ffffff;
    --text-color: #373737;
    --gray-base: #494949;
    --gray-darker: #222;
    --gray-dark: #333;
    --gray: #555;
    --gray-light: #777;
    --gray-lighter: #eee;
    --brand-primary: #1f9eba;
    --brand-success: #59ba1f;
    --brand-info: #5bc0de;
    --brand-warning: #d1bd10;
    --brand-danger: #ba1f1f;
}

/* Timeline */
.timeline {
    list-style: none;
    padding: 20px;
    position: relative;
    &:before {
        top: 40px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: 50%;
        margin-left: -1.5px;
    }
    .timeline-item {
        margin-bottom: 20px;
        position: relative;
        &:before,
        &:after {
            content: "";
            display: table;
        }
        &:after {
            clear: both;
        }
        .timeline-badge {
            color: #fff;
            width: 54px;
            height: 54px;
            line-height: 52px;
            font-size: 22px;
            text-align: center;
            position: absolute;
            top: 18px;
            left: 50%;
            margin-left: -25px;
            /*background-color: var(--gray-dark);*/
            border: 3px solid var(--body-bg);
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            i, .fa, .glyphicon {
                top: 2px;
                left: 0px;
            }
            &.primary {
                background-color: var(--brand-primary);
            }
            &.info {
                background-color: var(--brand-info);
            }
            &.success {
                background-color: var(--brand-success);
            }
            &.warning {
                background-color: var(--brand-warning);
            }
            &.danger {
                background-color: var(--brand-danger);
            }
        }
        .timeline-panel {
            position: relative;
            width: 46%;
            float: left;
            right: 16px;
            /*border: 1px solid var(--gray-light);*/
            /*background: var(--body-bg);*/
            /*border-radius: 2px;*/
            padding: 20px;
            -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            &:before {
                position: absolute;
                top: 26px;
                right: -16px;
                display: inline-block;
                border-top: 16px solid transparent;
                /*border-left: 16px solid var(--gray-light);*/
                /*border-right: 0 solid var(--gray-light);*/
                border-bottom: 16px solid transparent;
                content: " ";
            }
            .timeline-title {
                margin-top: 0;
                color: inherit;
            }
            .timeline-body > p,
            .timeline-body > ul {
                margin-bottom: 0;
            }
            .timeline-body > p + p {
                margin-top: 5px;
            }
        }
    }
    .timeline-item:last-child {
        &:nth-child(even) {
            float: right;
        }
    }
    .timeline-item:nth-child(even) {
        .timeline-panel {
            float: right;
            left: 16px;
            &:before {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }
        }
    }
}
/*.timeline-horizontal:extend(.timeline) {*/
.timeline, .timeline-horizontal {
    list-style: none;
    position: relative;
    /*padding: 20px 0 20px 0;*/
    margin-bottom: 0!important;
    display: inline-block;
    &:before {
        height: 3px;
        top: 175px;
        bottom: 26px;
        left: 56px;
        right: 0;
        width: 92%;
        margin-bottom: 20px;
    }
    .timeline-item {
        display: table-cell;
        height: 200px;
        width: 20%;
        min-width: 250px;
        float: none !important;
        padding-left: 0;
        margin: 0 auto;
        vertical-align: bottom;
        .timeline-panel {
            top: auto;
            bottom: 64px;
            display: inline-block;
            float: none !important;
            left: 0 !important;
            right: 0 !important;
            width: 100%;
            margin-bottom: 20px;
            &:before {
                top: auto;
                bottom: -16px;
                left: 28px !important;
                right: auto;
                border-right: 16px solid transparent !important;
                border-top: 16px solid var(--gray-light) !important;
                border-bottom: 0 solid var(--gray-light) !important;
                border-left: 16px solid transparent !important;
            }
        }
        &:before,
        &:after {
            display: none;
        }
        .timeline-badge {
            top: 130px;
            bottom: 0;
            left: 43px;
        }

    }
}

@media only screen and (max-width: 768px) {
    .timeline, .timeline-horizontal {
        &:before {
            top: -100px;
        }
    }
}
