.box-link{
    color:#fff!important;
    text-decoration:none!important;
}
.box-link:hover{
    color:#cc992d!important;
    text-decoration:none!important;
}
.content{
    /*font-size: 20px;*/
    font-size: 1.2em;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(8px); /* Appliquer le flou */
    z-index: 0;
}

.container {
    position: relative;
    z-index: 2;
    color: white; /* Exemple de style pour le texte */
}

@media only screen and (max-width: 700px) {
    .content{
        font-size: 1em;
    }
}
