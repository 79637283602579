body, html {
    height: 100%;
    margin: 0;
}

#hero .overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* ajustez l'opacité pour obtenir l'effet désiré */
    z-index: 1;
}

#hero .container {
    position: relative;
    z-index: 2;
    color: white; /* Exemple de style pour le texte */
}

.box-link{
    color:#fff!important;
    text-decoration:none!important;
}
.box-link:hover{
    color:#cc992d!important;
    text-decoration:none!important;
}
.plate-form{
    color:#e5e7ebc2!important;
}