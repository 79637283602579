.img-design{
    height: 20%;
    width: 20%;
    display:inline-block!important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  /*background-color: #0b1e5e!important;*/
  background-color: #ffffff!important;
  /*border-bottom: 5px solid #cc992d;*/
  justify-content: center;
  display: flex;
  align-items: center;
}

#header.header-scrolled,
#header.header-inner-pages {
   background: rgba(0, 0, 0, 0.8);
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo a span {
  color: #ffc451;
}

#header .logo img {
  max-height: 40px;
}

#hero h2 {
    font-size: 30px!important;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

@media screen and (min-width: 1280px) {
    .img-design {
        height: 20%;
        width: 10%!important;
    }
}

@media only screen and (min-width: 822px) and (max-width: 1920px){
   .img-design {
        height: 20%;
        /*width: 13%!important;*/
    }
    #hero h2 {
        font-size: 45px!important;
    }
}



