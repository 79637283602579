/*@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
body {
  font-family: "Ubuntu", sans-serif;
  color: #444444;
  /* overflow:hidden!important; */
  /* height:100vh; */
}
.welcome-header {
  color: white !important;
}
.bvn {
  margin-bottom: 2rem;
}
.ctn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px 15rem;
}

a {
  color: #ffc451;
  text-decoration: none;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}

section,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
label,
span,
small {
  font-family: "Ubuntu", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #ffc451;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #151515;
  line-height: 0;
}

.back-to-top:hover {
  background: #151515;
}

.back-to-top:hover i {
  color: #ffc451;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #151515;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid #ffc451;
  border-top-color: #151515;
  border-bottom-color: #151515;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

@media screen and (max-width: 1199px) and (min-width: 999px) {
  /* styles for screens between 480px and 768px wide */
  .responsive-alignment-header {
    font-size: 20px !important;
  }
  .responsive-alignment-container {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    margin: 0.1rem !important;
  }
  /* 0 */
  .responsive-alignment-attention {
    font-size: 11px !important;
  }
  .responsive-alignment {
    font-size: 14px !important;
  }
  .responsive-alignment-element {
    font-size: 15px !important;
  }
  .transmettre {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .tansmettre-input {
    margin-bottom: 1rem !important;
  }
}

@media only screen and (min-width: 390px) and (max-width: 844px) {
  .responsive-alignment-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.1rem !important;
  }
  .responsive-alignment-header {
    font-size: 22px !important;
  }
  .responsive-alignment-attention {
    font-size: 11px !important;
  }
  .responsive-alignment {
    font-size: 17px !important;
  }
  .transmettre {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .tansmettre-input {
    margin-bottom: 1rem !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .responsive-alignment-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.1rem !important;
  }
  .responsive-alignment-header {
    font-size: 20px !important;
  }
  .responsive-alignment-attention {
    font-size: 15px !important;
  }
  .responsive-alignment {
    font-size: 15px !important;
  }
  .transmettre {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .tansmettre-input {
    margin-bottom: 1rem !important;
  }
}
@media only screen and (min-width: 360px) and (max-width: 740px) {
  .responsive-alignment-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.1rem !important;
  }
  .responsive-alignment-header {
    font-size: 20px !important;
  }
  .responsive-alignment {
    font-size: 15px !important;
  }
  .responsive-alignment-attention {
    font-size: 15px !important;
  }
  .transmettre {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .tansmettre-input {
    margin-bottom: 1rem !important;
  }
}
@media only screen and (min-width: 760px) and (max-width: 1024px) {
  .responsive-alignment-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.1rem !important;
  }
  .responsive-alignment-header {
    font-size: 20px !important;
  }
  .responsive-alignment {
    font-size: 15px !important;
  }
  .responsive-alignment-attention {
    font-size: 15px !important;
  }
  .transmettre {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .tansmettre-input {
    margin-bottom: 1rem !important;
  }
}

/* device from 360px , 740px (phones 740px and down) */
@media only screen and (min-width: 360px) and (max-width: 767px) {
  body {
    overflow: visible !important;
  }
  /*--------------------------------------------------------------
# Landing page mobile
--------------------------------------------------------------*/
  .get-started-btn {
    font-size: 20px !important;
    margin-right: 0 !important;
    padding: 0 !important;
  }
  .img-design {
    width: 45% !important;
  }

  #hero h1 {
    /*min-height: 100vh !important;*/
  }

  #hero h1 {
    margin-top: 2rem !important;
    /* font-size: 1.5rem !important; */
  }

  #hero .icon-box h3 {
    /*margin-top:2rem !important;*/
    font-size: 1.5rem !important;
  }
  .pouvoir-bien-mener {
    font-size: 1.3rem !important;
    font-family: "Ubuntu", sans-serif;
  }
  #footer .copyright {
    font-size: 12px !important;
  }
  #hero .container {
    /* margin-top:4rem !important; */
  }
  .welcome-header {
    margin-top: 1rem !important;
    font-size: 18px !important;
    color: white !important;
  }
  .declare-oui,
  .declare-non {
    font-size: 18px !important;
  }
  /*--------------------------------------------------------------
# Recherche Du Souscripteur mobile
--------------------------------------------------------------*/
  .type-home {
    display: flex;
    flex-direction: column;
  }
  .form-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .type-container {
    margin-top: 0 !important;
  }
  .info-sous-header {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    font-size: 0.8rem !important;
  }

  .info-sous-btn {
    display: flex;
    align-items: center;
    margin: 1;
    height: -webkit-fill-available;
  }

  .info-sous-recherche {
    width: 20% !important;
  }
  .police-btn {
    justify-center: center;
    align-items: center;
  }
  .police-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .police-return-btn {
    width: inherit !important;
  }
  .police-recherche-btn {
    width: 0 !important;
  }
  .num-client-container {
    justify-content: center;
    align-items: center;
  }
  .num-client-btn {
    justify-content: center;
    align-items: center;
  }
  .num-client-return-btn {
    width: auto !important;
  }
  .num-client-recherche-btn {
    width: 0 !important;
  }
  /*--------------------------------------------------------------
# Declaration sinistre mobile
--------------------------------------------------------------*/
  .declaration-sinistre-mobile {
    margin-top: 0 !important;
  }
  .signup-multi-mobile {
    display: table !important;
  }
  .gutter-mobile {
    bs-gutter-y: 0 !important;
  }
  .container-mobile {
    margin-top: 0 !important;
  }
  .section-title-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: inherit !important;
    margin-left: 1rem !important;
  }
  .souscripteur-btn-mobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .souscripteur-btn-back {
    margin-right: 0.5rem !important;
  }
  .flex-col-mobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .stepper-mobile {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .stepper-container-horizontal {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  /*--------------------------------------------------------------
# Declaration sinistre mobile - Declarant
--------------------------------------------------------------*/
  .civilite-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .civilite-mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-col-mobile-document {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .choisir-fichier {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-bottom: 2rem;
  }
  .ajouter-autre {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sous-resume {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    /*margin-bottom: 4rem;*/
  }
  .sous-defunt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    /*margin-bottom: 4rem;*/
  }
  .nouvelle-declaration {
    font-size: 9px !important;
  }
  .suivre-son-dossier {
    font-size: 9px !important;
  }

  /* --------------------------------------------------------------
# Suivre Son Dossier mobile - login page
-------------------------------------------------------------- */
  .login-btn-mobile {
    height: fit-content;
  }
  .renvoyer-numero-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .avez-vous-oublier {
    font-size: 13px !important;
  }
  /* .table-grid-section{
   height: 0!important;
 } */
  /* #hero{
  height:100vh!important;
} */
  .login-container-mobile {
    padding: 0 !important;
  }
  .login-card-body-mobile {
    padding: 0 !important;
  }
  .suivre-home-section-mobile {
    padding: 0 !important;
    overflow: visible !important;
  }
  .table-grid-section {
    padding: 0 !important;
  }
  .table-grid-section-container {
    padding: 0 !important;
  }
  .section-title-suivrehome {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: inherit !important;
    margin-left: 1rem !important;
    margin-top: 1.2rem !important;
  }
  .declaration-courriers {
    padding: 0 !important;
  }
  /* .suivrehome-hero{
  height:0!important;
 } */
  .tbody-mobile {
    background-color: #4b5563 !important;
  }
  .status-container {
    padding: 0 !important;
  }
  /* --------------------------------------------------------------
# Assistance mobile design - login page
-------------------------------------------------------------- */
  .box-content {
    display: flex !important;
    justify-content: between !important;
    align-items: center !important;
    flex-direction: column-reverse !important;
  }
  .assistanceContainer {
    padding: 0 !important;
  }
  .css-vph3ya-MuiTabs-root .MuiTab-root {
    font-size: 0.9rem !important;
    margin: 0.8rem !important;
    width: 90% !important;
  }
  .disponibilite-container {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 2.5rem !important;
  }
  .nom-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .card-body-padding {
    padding: 0 !important;
  }
  .valider-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: fit-content !important;
  }
  .abouve-zoom-container {
    min-width: 128% !important;
  }
  .global-container {
    padding-top: 0 !important;
  }
  .code-box-rendez-vous {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  .box-mobile {
    padding: 0 !important;
  }
  /* .bvn-phrase{
font-size:17px!important
}
.plate-form{
font-size:17px;
} */
  .welcome-padding {
    padding: 1rem !important;
  }
  .tutorial-link {
    font-size: 15px !important;
  }
  /*.img-design{*/
  /*  width: 10%!important;*/
  /*}*/

  /* .container-mobile{
  width:fit-content!important;
} */
}
/* device from 820px , 1180px (phones 820px and down) */
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .bvn {
    margin-top: 5rem !important;
  }
  .welcome-container {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .souhaite {
    font-size: 24px !important;
  }
  .bvn-phrase {
    font-size: 55px !important;
  }
  .plate-form {
    color: #e5e7ebc2 !important;
    font-size: xx-large !important;
  }
  .ipad-design {
    background-color: rgb(0, 0, 0, 0.2) !important;
  }
}
/* device from 768px , 1024px (phones 768px and down) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .bvn {
    margin-top: 5rem !important;
  }
  .welcome-container {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .ipad-design {
    background-color: rgb(0, 0, 0, 0.2) !important;
  }
  .img-design {
    width: 30% !important;
  }
  .attention-text {
    font-size: 10px;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  height: 10vh;
}

#header.header-inner-pages {
  background: rgba(0, 0, 0, 0.8);
}
#header.header-scrolled {
  background: "none";
}

#header .logo {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo a span {
  color: #ffc451;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
#  Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  color: #ffffff;
  /* border-radius: 4px; */
  /*padding: 7px 25px 8px 25px;*/
  white-space: nowrap;
  transition: 0.3s;
  font-size: 40px;
  display: inline-block;
  /* border: 2px solid #ffc451;
  background: #ffbb38; */
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #ffc451;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #151515;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  background-color: #ffc451;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #151515;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #151515;
  background-color: #ffc451;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
  color: #151515;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  background-color: #ffc451;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  /* padding-top: 74px; */
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
}

#hero h1 span {
  color: #ffc451;
}

#hero .icon-box {
  padding: 1rem;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  height: 100%;
  text-align: center;
  /*background-color: #0b1e5e;*/
  background-color: #0187be;
}

#hero .icon-box i {
  font-size: 60px;
  line-height: 1;
  color: #ffc451;
}

#hero .icon-box h3 {
  font-weight: 500;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 30px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
  color: #ffc451;
}

#hero .icon-box:hover {
  border-color: #ffc451;
}
@media (max-width: 768px){
  #hero .icon-box {
      padding: 1rem;
  }
  #hero .icon-box svg{
    height: auto!important;
    width:50%!important;
  }
  #hero .icon-box .decla-icon{
    width:25%!important;
  }
  #hero .icon-box h3{
    font-size: 20px!important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: visible;
}
.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Ubuntu", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Ubuntu", sans-serif;
  color: #151515;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
  font-family: "Ubuntu", sans-serif;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 8px 26px;
  position: relative;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: #ffc451;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding-top: 20px;
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  filter: none;
  opacity: 1;
}

.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  background-color: #ddd;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  padding-top: 20px;
}

.features .icon-box {
  padding-left: 15px;
}

.features .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .icon-box i {
  font-size: 48px;
  float: left;
  color: #ffc451;
}

.features .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.features .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ffc451;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #151515;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
  color: #ffc451;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
/* url("../img/cta-bg.jpg") */
.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)),
    fixed center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #ffc451;
  border-color: #ffc451;
  color: #151515;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #151515;
  background: #ffc451;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #ffc451;
}
/*
.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
} */

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ffc451;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #ffc451;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .content {
  padding: 30px 0;
}

.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #151515;
}

.counts .content p {
  margin-bottom: 0;
}

.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}

.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #ffc451;
  float: left;
}

.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #151515;
  margin-left: 50px;
}

.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  color: #3b3b3b;
}

.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #3b3b3b;
  font-size: 15px;
  font-family: "Ubuntu", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
  color: #626262;
}

.counts .image {
  /* background: url("../img/counts-img.jpg") center center no-repeat; */
  background-size: cover;
  min-height: 400px;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  /* background: url("../img/testimonials-bg.jpg") no-repeat; */
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
  opacity: 1;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #151515;
  margin: 0 3px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.8);
  transition: ease-in-out 0.3s;
  color: #484848;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.team .member .social a:hover {
  color: #151515;
  background: #ffc451;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #151515;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  background: #ffc451;
  color: #151515;
  float: left;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #151515;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #484848;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #ffc451;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #ffc451;
  border: 0;
  padding: 10px 24px;
  color: #151515;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #ffcd6b;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: whitesmoke;
  min-height: 40px;
  margin-top: 74px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #2f2f2f;
  content: "/";
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  /*background-color: transparent;*/
  position: fixed;
  /*padding: 8px;*/
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  /*color: #fff;*/
  /*text-shadow: 0 1px 2px rgb(0 0 0 / 25%);*/
  /*box-shadow: 0 -2px 3px rgb(0 0 0 / 30%);*/
  /*border-top: 2px solid #121b44 !important;*/
}

#footer .footer-top {
  background: #001e60;
  /*border-bottom: 1px solid #222222;*/
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
  color: #ffc451;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Ubuntu", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #292929;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ffc451;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ffc451;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #ffc451;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #ffc451;
  color: #151515;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #ffcd6b;
}

#footer .copyright {
  /*text-align: center;*/
  font-size: 0.9rem;
  font-family: "Ubuntu";
  color: #fff;
  padding: 8px 16px;
  text-shadow: 0 1px 2px rgb(0 0 0 / 25%);
  box-shadow: 0 -2px 3px rgb(0 0 0 / 30%);
  /*border-top: 2px solid #121b44 !important;*/
  background-color: #001e60;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

.card-body span .form-required {
  color: #cc992d !important;
}

/*<!--Datatables -->*/
table thead th,
table thead td {
  border-bottom: none !important;
  background-color: #0187be !important;
  color: #ffffff !important;
}

table tbody tr {
  background-color: #005780 !important;
}

table tbody td {
  background-color: #005780 !important;
  color: #ffffff !important;
  padding: 0.5rem !important;
}

/*.courrier-pas-ouvert {*/
/*  background-color: #4f545c !important;*/
/*  font-weight: medium;*/
/*}*/

/*------scroll bar---------------------*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cc992d;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cc992d;
}

::-webkit-scrollbar-thumb:active {
  background: #cc992d;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.btn-primary {
  background-color: #001e60!important;
  color: #fff!important;
  border-color: #001e60!important;
}

.btn-warning {
  background-color: #c88025!important;
  color: #fff!important;
  border-color: #c88025!important;
}

.btn-info {
  background-color: #0187be!important;
  color: #fff!important;
  border-color: #0187be!important;
}

.font-primary {
  background-color: #001e60!important;
  color: #ffffff!important;
}

.font-warning {
  background-color: #c88025!important;
  color: #ffffff!important;
}

.font-info {
  background-color: #0187be!important;
  color: #ffffff!important;
}

.font-secondary {
  background-color: #b1b1b2!important;
  color: #ffffff!important;
}

.line-primary {
  border: 1px solid #001e60!important;
  color: #001e60!important;
}

.line-info {
  border: 1px solid #0187be!important;
  color: #0187be!important;
}

.line-warning {
  border: 1px solid #c88025!important;
  color: #ffffff!important;
}

.border-input {
  border: 3px solid #0187be!important;
  color: #3f3f3f
}

.form-control {
  color: #3f3f3f!important;
  border: 3px solid #0187be!important;
  border-radius: 0!important;
}

.Toastify__toast-theme--dark {
  background: #001e60!important;
  color: #ffffff!important;
}

.card-title {
  background-color: #001e60!important;
  color: #ffffff!important;
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.card-content {
  background-color: #ffffff!important;
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.card-table {
  background-color: #005780!important;
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.card-content label {
  color: #001e60;
}

dt span {
  color: #001e60!important;
}

.MuiBreadcrumbs-li a {
  color: #ffffff !important;
}

.MuiBreadcrumbs-separator {
  color: #c88025 !important;
}

:focus-visible {
  outline: #0187be auto 3px !important;
}

@media only screen and (max-width: 768px) {
  small {
    font-size: 13px !important;
  }

  select option {
    background-color: grey!important;
    color: #ffffff!important;
  }
}
