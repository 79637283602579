.login-container{
    height: 92.7vh;
}

.table {
    font-size: 1rem !important;
}

@media only screen and (max-width: 768px) {
    .table {
        font-size: 13px !important;
    }
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }

   body {
   overflow-y: visible;
   overflow-x: visible;
}

}

@media (max-width: 768px) {

   body {
   overflow-y: visible;
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    /*margin-top:3rem;*/
    /*font-size: 20px;*/
    /*line-height: 36px;*/
    /*margin-bottom: 2rem*/
  }

}
 /* @media only screen and (min-width: 360px) and (max-width: 704px){

#hero h1 {
  font-size: 25px!important;

}
.acceuil{
  margin-top: 4rem;
}

} */

/* Extra small devices (phones, 1024px and down) */
  /* @media only screen and (min-width: 768px) and (max-width: 1024px){
   #hero {
    height: auto;
  }
   body {
   overflow-y: visible;
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    margin-top:3rem;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 2rem
  }

} */

 .animation {
     position: relative;
     left: 0;
     animation: animation 4s ease-out infinite;
 }

@keyframes animation {
      0% {
        opacity: 0;
        left: -700px;
      }

      100% {
        opacity: 1;
        left: 0;
      }
}

 .blink {
     animation: blink 2s ease-out infinite;
 }

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@keyframes parade {
  0% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(70px);
  }
  100% {
    transform: translateX(0);
  }
}
@media only screen and (min-width: 822px) and (max-width: 1920px){
 .tableGrid-container{
    margin-top: 4rem!important;
    width: min-content!important;
 }
}
