#table-grid-container{
 height: '92.7vh';
 backgroundColor: 'gray';
 display: 'flex';
 justifyContent: 'center';
alignItems: 'center' ;
}
.table-grid-section-container{
   padding:1.5rem;
}
#table-container{
    height: '65vh'
}
.status-container{
   padding-top:0.5rem;
   padding-bottom:0.5rem;
   padding-left:1.5rem;
   padding-right:1.5rem;
}
.tableGrid-container{
    margin-top: 4rem!important;
    width: min-content!important;
 }
 .table-grid-section{
   padding:60px;
   height:100vh;
 }
 .declaration-courriers{
   padding:1.5rem;
 }
 /*--------------------------------------------------------------
# suivrehome-hero Section
--------------------------------------------------------------*/
#suivrehome-hero {
  width: 100%;
  /* height:100vh; */
  background-size: cover;
  position: relative;
}

#suivrehome-hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#suivrehome-hero .container {
  position: relative;
  /* padding-top: 74px; */
  text-align: center;

}

#suivrehome-hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
}

#suivrehome-hero h1 span {
  color: #ffc451;
}


#suivrehome-hero .icon-box {
  padding: 100px 50px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}

#suivrehome-hero .icon-box i {
  font-size: 80px;
  line-height: 1;
  color: #ffc451;
}

#suivrehome-hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 30px;
  line-height: 26px;
}

#suivrehome-hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#suivrehome-hero .icon-box h3 a:hover {
  color: #ffc451;
}

#suivrehome-hero .icon-box:hover {
  border-color: #ffc451;
}
/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media only screen and (min-width: 822px) and (max-width: 1920px){
 .tableGrid-container{
    margin-top: 4rem!important;
    width: min-content!important;
 }
}
.card{
 background-color:transparent!important;
}
.card-header{
   background-color:#e3e6e7!important;
}
.card-body{
   background-color: rgba(0, 0, 0, 0.3)!important;
}
.card-body label{
   color:#001e60
}
 .card-body span{
   color:#e3e6e7!important
}
.error-color{
   color:red!important
}
.box-link{
    color:#fff!important;
    text-decoration:none!important;
}
.box-link:hover{
    color:#cc992d!important;
    text-decoration:none!important;
}
.return-btn{
   background-color:#e3e6e7;
   color:#232e62;
    font-size: 20px;
   font-family: "Ubuntu", sans-serif;
}
.section-title{
    color:#caa33f!important;
    padding-bottom:0.4rem;
}
.return-btn:hover{
    background-color: #121b44;
    color:#fff
}

